<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
          :src="require('@/assets/images/logo/logo.png')"
          alt="logo"
      />
      <h2 class="brand-text text-primary ml-1">
        Vuexy
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          You are not authorized! 🔐
        </h2>
        <p class="mb-2">
          You don’t have permission to access this page. Go Home!!
        </p>
        <b-button
            variant="primary"
            class="mb-1 btn-sm-block"
            :to="{name: 'dashboard'}"
        >Back to Home
        </b-button>
        <b-img
            fluid
            :src="require('@/assets/images/pages/not-authorized.svg')"
            alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { getHomeRouteForLoggedInUser } from '@/utils/utils'

export default {
  components: {},
  data () {
    return {}
  },
  computed: {},
  methods: {
    // loginRoute () {
    //   const user = JSON.parse(localStorage.getItem('userData'))
    //   return getHomeRouteForLoggedInUser(user ? user.role : null)
    // },
  },
}
</script>

<style lang="scss">
@import '~@/assets/scss/base/pages/misc.scss';
</style>
